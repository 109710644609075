var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form",
        {
          attrs: { enctype: "multipart/form-data" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitUpdate.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-card",
            { staticClass: "mb-1", attrs: { "no-body": "" } },
            [
              _c("b-card-header", [
                _c(
                  "div",
                  [
                    _c("b-card-title", { staticClass: "mb-1" }, [
                      _vm._v(" Dados fiscais "),
                    ]),
                    _c("b-card-sub-title", [
                      _vm._v(
                        " Os dados inseridos abaixo serão utilizados na emissão de nota fiscal. "
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("b-card-body", { staticClass: "pl-2 pr-2" }, [
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Tipo da Pessoa "),
                        ]),
                        _c(
                          "v-select",
                          {
                            class: { "is-invalid": _vm.$v.item.type.$error },
                            attrs: {
                              searchable: false,
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              options: _vm.optionsTypeCompanies,
                            },
                            model: {
                              value: _vm.item.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "type", $$v)
                              },
                              expression: "item.type",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" CNPJ/CPF "),
                        ]),
                        _c("b-input-group", [
                          _c("input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["###.###.###-##", "##.###.###/####-##"],
                                expression:
                                  "['###.###.###-##', '##.###.###/####-##']",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.document,
                                expression: "item.document",
                              },
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid": _vm.$v.item.document.$error,
                            },
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.document },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item,
                                  "document",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Inscrição Estadual "),
                        _c("i", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.top",
                              value:
                                "A inscrição estadual é o registro das empresas perante a Receita Estadual.",
                              expression:
                                "\n                    'A inscrição estadual é o registro das empresas perante a Receita Estadual.'\n                  ",
                              modifiers: { hover: true, top: true },
                            },
                          ],
                          staticClass: "bi bi-question-circle",
                        }),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.state_license,
                            expression: "item.state_license",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.item.state_license },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "state_license",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Inscrição Municipal "),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.city_license,
                            expression: "item.city_license",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.item.city_license },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "city_license",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Nome Fantasia "),
                        _c("i", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.top",
                              value: "Nome de fantasia ou apelido",
                              expression: "'Nome de fantasia ou apelido'",
                              modifiers: { hover: true, top: true },
                            },
                          ],
                          staticClass: "bi bi-question-circle",
                        }),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.company_name,
                            expression: "item.company_name",
                          },
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid": _vm.$v.item.company_name.$error,
                        },
                        attrs: { type: "text" },
                        domProps: { value: _vm.item.company_name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "company_name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-4" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Nome "),
                        _c("i", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.top",
                              value:
                                "Nome registrado na junta comercial ou nome completo do contato.",
                              expression:
                                "\n                    'Nome registrado na junta comercial ou nome completo do contato.'\n                  ",
                              modifiers: { hover: true, top: true },
                            },
                          ],
                          staticClass: "bi bi-question-circle",
                        }),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.trading_name,
                            expression: "item.trading_name",
                          },
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid": _vm.$v.item.trading_name.$error,
                        },
                        attrs: { type: "text" },
                        domProps: { value: _vm.item.trading_name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "trading_name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-group mb-0" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Simples Nacional "),
                        ]),
                        _c(
                          "v-select",
                          {
                            class: {
                              "is-invalid": _vm.$v.item.simple_national.$error,
                            },
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              placeholder: "Digite o nome",
                              options: [
                                { title: "Sim", code: 1 },
                                { title: "Não", code: 0 },
                              ],
                            },
                            model: {
                              value: _vm.item.simple_national,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "simple_national", $$v)
                              },
                              expression: "item.simple_national",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(" Categoria "),
                        ]),
                        _c(
                          "v-select",
                          {
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              placeholder: "Digite o nome",
                              options: _vm.optionsCategories,
                              searchable: false,
                            },
                            model: {
                              value: _vm.item.category,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "category", $$v)
                              },
                              expression: "item.category",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c("div", { staticClass: "form-group mb-md-0" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Abertura da empresa "),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.date,
                            expression: "item.date",
                          },
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: ["##/##/####"],
                            expression: "['##/##/####']",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", placeholder: "dd/mm/aaaa" },
                        domProps: { value: _vm.item.date },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.item, "date", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-group mb-md-0" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Situação "),
                        ]),
                        _c(
                          "v-select",
                          {
                            class: { "is-invalid": _vm.$v.item.active.$error },
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              searchable: false,
                              options: _vm.optionsStatus,
                            },
                            model: {
                              value: _vm.item.active,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "active", $$v)
                              },
                              expression: "item.active",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-card",
            { staticClass: "mb-1", attrs: { "no-body": "" } },
            [
              _c("b-card-header", [
                _c(
                  "div",
                  [
                    _c("b-card-title", { staticClass: "mb-1" }, [
                      _vm._v(" Contatos "),
                    ]),
                    _c("b-card-sub-title", [
                      _vm._v(" Cadastre os meios de contato do fornecedor "),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("b-card-body", { staticClass: "pl-2 pr-2" }, [
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("div", { staticClass: "form-group mb-0 mb-m-1" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v(" E-mail ")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.email,
                            expression: "item.email",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "email" },
                        domProps: { value: _vm.item.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.item, "email", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-2" }, [
                    _c("div", { staticClass: "form-group mb-0 mb-m-1" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Telefone "),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: ["(##) ####-####", "(##) #####-####"],
                            expression: "['(##) ####-####', '(##) #####-####']",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.phone,
                            expression: "item.phone",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "tel" },
                        domProps: { value: _vm.item.phone },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.item, "phone", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-2" }, [
                    _c("div", { staticClass: "form-group mb-0 mb-m-1" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Celular "),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: ["(##) ####-####", "(##) #####-####"],
                            expression: "['(##) ####-####', '(##) #####-####']",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.cellphone,
                            expression: "item.cellphone",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "tel" },
                        domProps: { value: _vm.item.cellphone },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.item, "cellphone", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-2" }, [
                    _c("div", { staticClass: "form-group mb-0 mb-m-1" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" WhatsApp "),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.whatsapp,
                            expression: "item.whatsapp",
                          },
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: ["(##) ####-####", "(##) #####-####"],
                            expression: "['(##) ####-####', '(##) #####-####']",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "tel" },
                        domProps: { value: _vm.item.whatsapp },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.item, "whatsapp", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-card",
            { staticClass: "mb-1", attrs: { "no-body": "" } },
            [
              _c("b-card-header", [
                _c(
                  "div",
                  [
                    _c("b-card-title", { staticClass: "mb-1" }, [
                      _vm._v(" Endereço "),
                    ]),
                    _c("b-card-sub-title", [
                      _vm._v(
                        " Digite o endereço onde a empresa está localizada "
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("b-card-body", { staticClass: "pl-2 pr-2" }, [
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-md-2" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v(" CEP ")]),
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: ["#####-###"],
                            expression: "['#####-###']",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.zipcode,
                            expression: "item.zipcode",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.item.zipcode },
                        on: {
                          keyup: function ($event) {
                            return _vm.searchCep()
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.item, "zipcode", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-2" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Número")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.number,
                            expression: "item.number",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.item.number },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.item, "number", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-2" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Complemento"),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.complement,
                            expression: "item.complement",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.item.complement },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "complement",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Endereço")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.address,
                            expression: "item.address",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.item.address },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.item, "address", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c("div", { staticClass: "form-group mb-0 mb-m-1" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Bairro")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.neighborhood,
                            expression: "item.neighborhood",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.item.neighborhood },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "neighborhood",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c("div", { staticClass: "form-group mb-0 mb-m-1" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Cidade")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.city,
                            expression: "item.city",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.item.city },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.item, "city", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-1" }, [
                    _c("div", { staticClass: "form-group mb-0 mb-m-1" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Estado")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.state,
                            expression: "item.state",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.item.state },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.item, "state", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-card",
            { staticClass: "mb-1", attrs: { "no-body": "" } },
            [
              _c("b-card-header", { staticClass: "mb-0" }, [
                _c("div", [
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-11" },
                      [
                        _c("b-card-title", { staticClass: "mb-1" }, [
                          _vm._v(" Documentos "),
                        ]),
                        _c("b-card-sub-title", [
                          _vm._v(
                            " Adicione documentos do fornecedor caso precise utilizar futuramente "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "col-md-1" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-block btn-primary",
                          attrs: { type: "button" },
                          on: { click: _vm.selectFiles },
                        },
                        [_c("i", { staticClass: "bi bi-plus-circle" })]
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("b-card-body", { staticClass: "p-2" }, [
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "form-group mb-0" }, [
                      _c("input", {
                        staticClass: "form-control-file hidden",
                        attrs: {
                          id: "files",
                          type: "file",
                          multiple: "",
                          accept:
                            ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf",
                        },
                        on: { change: _vm.previewDocs },
                      }),
                      !_vm.item.docs.length && !_vm.docs.length
                        ? _c(
                            "p",
                            {
                              staticClass: "pt-5 py-4 text-center mb-0",
                              staticStyle: {
                                border: "1px solid rgb(222 222 222)",
                                "border-radius": "4px",
                                "border-style": "dashed",
                                "font-size": "1.1rem",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "bi bi-file-earmark-text",
                              }),
                              _vm._v(" Selecionar documentos "),
                              _c(
                                "small",
                                { staticClass: "text-center mt-1 d-block" },
                                [
                                  _vm._v(
                                    " Selcione apenas arquivos nos formatos: xlsx, xls, imagem, doc, docx, ppt, pptx, txt e pdf "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.docs.length
                        ? _c(
                            "label",
                            { staticClass: "d-block text-center mb-0" },
                            [
                              _c(
                                "ul",
                                {
                                  staticClass: "list-group list-group-light",
                                  attrs: { id: "array-files" },
                                },
                                _vm._l(_vm.docs, function (doc, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      staticClass:
                                        "list-group-item border-0 bg-light rounded-5 mb-5-px text-left",
                                      staticStyle: { "margin-bottom": "2px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex gap-column-20 items-center align-items-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-center mr-auto",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "" + doc.url,
                                                    target: "_blank",
                                                    rel: "noopener noreferrer",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    class:
                                                      "bi bi-filetype-" +
                                                      doc.extension,
                                                  }),
                                                  _vm._v(
                                                    " " + _vm._s(doc.name) + " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-left bd-highlight",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(doc.created_at) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-center bd-highlight",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("bytesToMB")(
                                                      doc.size,
                                                      2,
                                                      "MB"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-center bd-highlight text-danger",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-danger btn-sm",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteMedia(
                                                        doc.code
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "bi bi-trash3",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.item.docs.length
                        ? _c(
                            "label",
                            { staticClass: "d-block text-center mb-0" },
                            [
                              _c(
                                "ul",
                                {
                                  staticClass: "list-group list-group-light",
                                  attrs: { id: "array-files" },
                                },
                                _vm._l(_vm.item.docs, function (doc, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      staticClass:
                                        "list-group-item border-0 bg-light rounded-5 mb-5-px text-left",
                                      staticStyle: { "margin-bottom": "2px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex gap-column-10 items-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "text-center" },
                                            [
                                              _c("i", {
                                                class:
                                                  "bi bi-filetype-" +
                                                  doc.name.split(".").pop(),
                                              }),
                                              _vm._v(
                                                " " + _vm._s(doc.name) + " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-center bd-highlight",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("bytesToMB")(
                                                      doc.size,
                                                      2,
                                                      "MB"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-card",
            { staticClass: "mb-1", attrs: { "no-body": "" } },
            [
              _c("b-card-header", [
                _c(
                  "div",
                  [
                    _c("b-card-title", { staticClass: "mb-1" }, [
                      _vm._v(" Observações gerais "),
                    ]),
                    _c("b-card-sub-title", [
                      _vm._v(
                        " Caso tenha mais alguma informação ou observação sobre a empresa "
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("b-card-body", { staticClass: "pl-2 pr-2" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.item.note,
                      expression: "item.note",
                    },
                  ],
                  staticClass: "d-block form-control",
                  attrs: { rows: "4" },
                  domProps: { value: _vm.item.note },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.item, "note", $event.target.value)
                    },
                  },
                }),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "form-row justify-content-end" }, [
            _c(
              "div",
              { staticClass: "col-md-2" },
              [
                _c("ButtonsActionsFooter", {
                  attrs: {
                    variant: "success",
                    submited: _vm.submited,
                    text: "Salvar",
                    subtext: "Aguarde...",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }